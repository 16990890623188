import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import classNames from 'classnames';

import {
  Tooltip,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  TextField,
  FormGroup,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import { FormattedMessageWrappedInSpan } from '../../misc';

const ENTER_KEY = 'Enter';

const CertificateDownloadForm = (props) => {
  const {
    intl,
    onDownloadCertificate,
    pendingRequests,
    failed,  
    isPrivacyConsentNeeded,
    isBibUnknown,
    bibnumber,
    isFaceSearchEvent,
    startNumbers,
  } = props;

  const [showError, setShowError] = useState(false);
  const [startnumber, setStartnumber] = useState(isBibUnknown ? '' : bibnumber);
  const [hasDataPermission, setHasDataPermission] = useState(false);
  const [showNoPermissionError, setShowNoPermissionError] = useState(false);
  const [bibNotInArrayOfAvailableOptions, setBibNotInArrayOfAvailableOption] = useState(false);

  const isPending = () => {
    return pendingRequests.length > 0;
  };

  const handleStartnumberInputChanged = (event) => {
    setShowError(false);
    setBibNotInArrayOfAvailableOption(false);
    setStartnumber(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === ENTER_KEY && !isPending()) {
      submitForm();
    }
  };

  const submitForm = () => {
    if (isPending()) {
      return;
    }
    if (isPrivacyConsentNeeded && !hasDataPermission) {
      setShowNoPermissionError(true);
    }
    if (!startnumber) {
      setShowError(true);
    }
    if (!isFaceSearchEvent && isBibUnknown && startNumbers && !startNumbers.includes(startnumber)) {
      setBibNotInArrayOfAvailableOption(true);
      return;
    }

    if (
      (startnumber && !isPrivacyConsentNeeded) ||
      (startnumber && isPrivacyConsentNeeded && hasDataPermission)
    ) {
      onDownloadCertificate(startnumber.trim());
    }
  };

  const getErrorMessage = () => {
    if (showError) {
      return intl.formatMessage(translations.inputErrorText);
    }
    if (failed || bibNotInArrayOfAvailableOptions) {
      return intl.formatMessage(translations.outcomeErrorText);
    }
    return false;
  };

  const not_ready = () => {
    if (isPrivacyConsentNeeded && !hasDataPermission) {
      return true;
    }
    if (isBibUnknown && !startnumber) {
      return true;
    }
    return isPending();
  };

  return (
    <div className="notification-container">
      <DialogContent>
        <div className="notification-body">
          {isPrivacyConsentNeeded && (
            <>
              <h1>
                <FormattedMessageWrappedInSpan
                  id="thumbnailPreviewAccountDetails.certificateDownloadForm.dataPrivacy.title"
                  defaultMessage="Permission Needed"
                  className="mb-15"
                />
              </h1>
              <FormattedMessageWrappedInSpan
                id="thumbnailPreviewAccountDetails.certificateDownloadForm.dataPrivacy.description"
                defaultMessage="To create your certificate, we need to get your race result data from the event organizer. Please provide your consent."
                className="mb-20"
              />
              <Tooltip
                title={
                  <FormattedMessageWrappedInSpan
                    id="thumbnailPreviewAccountDetails.certificateDownloadForm.dataPrivacy.error"
                    defaultMessage="This consent is necessary to generate your certificate."
                  />
                }
                open={showNoPermissionError}
                placement="bottom"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hasDataPermission}
                      onChange={() => {
                        if (showNoPermissionError) {
                          setShowNoPermissionError(false);
                        }
                        setHasDataPermission((prevState) => !prevState);
                      }}
                    />
                  }
                  label={
                    <FormattedMessageWrappedInSpan
                      id="thumbnailPreviewAccountDetails.certificateDownloadForm.dataPrivacy.consent"
                      defaultMessage="I agree to let the event organizer share my race result data with Sportograf to create my certificate."
                    />
                  }
                />
              </Tooltip>
              {isBibUnknown && <div className='notification-divider notification-divider_mb-60'/>}
            </>
          )}
          {isBibUnknown && <>
          <h1>
            <FormattedMessageWrappedInSpan
              id="thumbnailPreviewAccountDetails.certificateDownloadForm.title"
              defaultMessage="Enter your bib number"
              className="mb-15"
            />
          </h1>
          <FormGroup>
            <TextField
              autoComplete="off"
              autoFocus={startnumber === ''}
              className="notification-input"
              error={getErrorMessage()}
              id="startnumber"
              helperText={getErrorMessage()}
              label={intl.formatMessage(translations.startnumberTextFieldLabel)}
              onChange={handleStartnumberInputChanged}
              onKeyPress={handleKeyPress}
              placeholder={intl.formatMessage(
                translations.startnumberTextFieldPlaceholder
              )}
              value={startnumber}
            />
          </FormGroup>
          </>}
        </div>
      </DialogContent>
      <DialogActions>
        <div className="notification-action with-spinner">
          <button
            className={classNames('accept', { disabled: not_ready() })}
            onClick={submitForm}
          >
            <FormattedMessageWrappedInSpan
              id="thumbnailPreviewAccountDetails.certificateDownloadForm.acceptButton"
              defaultMessage="Download"
            />
            {isPending() && (
              <div className="download-dropdown-expand-icon-container">
                <CircularProgress
                  classes={{ circle: 'circular-progress-circle-copy-color-medium' }}
                  size={20}
                  thickness={3}
                />
              </div>
            )}
          </button>
        </div>
      </DialogActions>
    </div>
  );
};

const translations = defineMessages({
  startnumberTextFieldLabel: {
    id: 'thumbnailPreviewAccountDetails.certificateDownloadForm.startnumberTextFieldLabel',
    defaultMessage: 'Bib Number',
  },
  startnumberTextFieldPlaceholder: {
    id: 'thumbnailPreviewAccountDetails.certificateDownloadForm.startnumberTextFieldPlaceholder',
    defaultMessage: 'Enter one bib number',
  },
  inputErrorText: {
    id: 'thumbnailPreviewAccountDetails.certificateDownloadForm.inputErrorText',
    defaultMessage: 'This field is required',
  },
  outcomeErrorText: {
    id: 'thumbnailPreviewAccountDetails.certificateDownloadForm.outcomeErrorText',
    defaultMessage: 'Could not download. Have you given a correct bib number?',
  },
});

CertificateDownloadForm.propTypes = {
  intl: PropTypes.object,
  onDownloadCertificate: PropTypes.func,
  pendingRequests: PropTypes.array,
  failed: PropTypes.bool,
  isPrivacyConsentNeeded: PropTypes.bool,
  isBibUnknown: PropTypes.bool,
  isFaceSearchEvent: PropTypes.bool,
  startNumbers: PropTypes.array,
};

export default injectIntl(CertificateDownloadForm);
export { CertificateDownloadForm };
