import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AccountDownloadTile from '../account/AccountDownloadTile';
import RestartProductionMutation from '../../../mutations/RestartProductionMutation';
import environment from '../../../environment';

import {
  RECHECK,
  ORIGINAL,
  CERTIFICATE,
  RESTART_PRODUCTION,
  RESTART_STATE_CAN_RESTART,
  SOCIAL_MEDIA,
} from '../../../utils/variables';

const ThumbnailPreviewAccountButtons = (props) => {
  const [uploadRestarted, setUploadRestarted] = useState(false);
  const [isRestartRequestPending, setIsRequestPending] = useState(false);
  const [isRestartError, setIsRestartError] = useState(false);

  const {
    photoNodeId,
    eventSgId,
    isFaceSearchEvent,
    hasCertificates,
    listType,
    originalUrl,
    socialMediaUrl,
    restartState,
    recheckHasOrder,
    certificateReadyForDwonload,
    hasBoughtFotoFlat,
    startNumbers
  } = props;

  const handleRestartUpload = () => {
    setIsRequestPending(true);
    RestartProductionMutation.commit(environment, eventSgId, (error, status) => {
      if (!error) {
        setUploadRestarted(true);
      } else {
        setIsRestartError(true);
      }
      setIsRequestPending(false);
    });
  };

  return (
    <>
      {originalUrl && (
        <a href={originalUrl} download>
          <AccountDownloadTile type={ORIGINAL} isWrappedInLink={true} />
        </a>
      )}
      {socialMediaUrl && (listType != RECHECK || recheckHasOrder) && (
        <a href={socialMediaUrl} download>
          <AccountDownloadTile type={SOCIAL_MEDIA} isWrappedInLink={true} />
        </a>
      )}
      {!originalUrl && restartState == RESTART_STATE_CAN_RESTART && (
        <AccountDownloadTile
          type={RESTART_PRODUCTION}
          onRequestHighResolutionPhoto={handleRestartUpload}
          notActive={uploadRestarted}
          isError={isRestartError}
          isPending={isRestartRequestPending}
        />
      )}
      {hasCertificates &&
        originalUrl &&
        listType != RECHECK &&
        certificateReadyForDwonload && 
        hasBoughtFotoFlat && (
          <AccountDownloadTile
            type={CERTIFICATE}
            certificateReadyForDwonload={certificateReadyForDwonload}
            photoNodeId={photoNodeId}
            isFaceSearchEvent={isFaceSearchEvent}
            eventSgId={eventSgId}
            startNumbers={startNumbers}
          />
        )}
    </>
  );
};

ThumbnailPreviewAccountButtons.propTypes = {
  photoNodeId: PropTypes.string,
  startNumbers: PropTypes.array,
  eventSgId: PropTypes.number,
  isFaceSearchEvent: PropTypes.bool,
  hasCertificates: PropTypes.bool,
  listType: PropTypes.string,
  originalUrl: PropTypes.string,
  socialMediaUrl: PropTypes.string,
  restartState: PropTypes.string,
  recheckStatus: PropTypes.string,
  recheckHasOrder: PropTypes.bool,
  certificateReadyForDwonload: PropTypes.bool,
  hasBoughtFotoFlat: PropTypes.bool,
  startNumbers: PropTypes.array,
};

export default ThumbnailPreviewAccountButtons;
