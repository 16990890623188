import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import CertificateDownloadForm from '../thumbnail/CertificateDownloadForm';
import CustomDialog from '../dialogs/CustomDialog';
import GenerateEventCertificateMutation from '../../../mutations/GenerateEventCertificateMutation';
import environment from '../../../environment';
import {
  BERLIN_MARATHON_EVENTID,
  SINGLE_MEDIA_ACCOUNT_PAGE,
} from '../../../utils/variables';
import AccountDownloadDialogTile from './AccountDownloadDialogTile';
import AccountDownloadTileButton from './AccountDownloadTileButton';

const AccountDownloadTileCertificate = (props) => {
  const {
    photoNodeId,
    eventSgId,
    onScrollToMedia,
    certificateReadyForDwonload,
    originalsAreAvailable,
    icon,
    textInSpan,
    type,
    isFaceSearchEvent,
    startNumbers,
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [failed, setFailed] = useState(false);

  const isPrivacyConsentNeeded = eventSgId === BERLIN_MARATHON_EVENTID;

  const { bibnumber } = useParams();

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const handleDownloadCertificate = (startNumber) => {
    addToPendingArray(startNumber);
    GenerateEventCertificateMutation.commit(
      environment,
      eventSgId,
      photoNodeId,
      startNumber,
      (error, url) => {
        if (!error) {
          setFailed(false);
          download(url);
        } else {
          setFailed(true);
        }
        removeFromPendingArray(startNumber);
      }
    );
  };

  const download = (url) => {
    window.location.href = url;
  };

  const addToPendingArray = (startNumber) => {
    let newArr = [...pendingRequests];
    newArr.push(startNumber);
    setPendingRequests(newArr);
  };

  const removeFromPendingArray = (startNumber) => {
    const newArr = pendingRequests.filter((item) => item !== startNumber);
    setPendingRequests(newArr);
  };


  // additional check if certificate is available
  if (!startNumbers || !startNumbers.includes(bibnumber)) {
    return null;
  }

  if (!photoNodeId) {
    // download area instructions
    return (
      <AccountDownloadDialogTile
        icon={icon}
        textInSpan={textInSpan}
        type={type}
        onScrollToMedia={onScrollToMedia}
        notActive={!certificateReadyForDwonload || !originalsAreAvailable}
        certificateReadyForDwonload={certificateReadyForDwonload}
        originalsAreAvailable={originalsAreAvailable}
      />
    );
  }

  // thumbnail preview download buttons
  return (
    <div className="thumbnail-btn-wrapper">
      <AccountDownloadTileButton
        icon={icon}
        textInSpan={textInSpan}
        onClick={() => {
          if (
            isFaceSearchEvent ||
            bibnumber === SINGLE_MEDIA_ACCOUNT_PAGE ||
            isPrivacyConsentNeeded
          ) {
            setDialogOpen(true);
          } else {
            handleDownloadCertificate(bibnumber);
          }
        }}
        isPending={pendingRequests.length > 0}
        error={failed}
      />
      <CustomDialog open={dialogOpen} onRequestClose={closeDialog}>
        <CertificateDownloadForm
          onDownloadCertificate={handleDownloadCertificate}
          pendingRequests={pendingRequests}
          failed={failed}
          isPrivacyConsentNeeded={isPrivacyConsentNeeded}
          isBibUnknown={isFaceSearchEvent || bibnumber === SINGLE_MEDIA_ACCOUNT_PAGE}
          bibnumber={bibnumber}
          startNumbers={startNumbers}
          isFaceSearchEvent={isFaceSearchEvent}
        />
      </CustomDialog>
    </div>
  );
};

AccountDownloadTileCertificate.propTypes = {
  photoNodeId: PropTypes.string,
  isFaceSearchEvent: PropTypes.bool,
  eventSgId: PropTypes.number,
  type: PropTypes.string,
  textInSpan: PropTypes.object,
  icon: PropTypes.object,
  onScrollToMedia: PropTypes.func,
  certificateReadyForDwonload: PropTypes.bool,
  originalsAreAvailable: PropTypes.bool,
  startNumbers: PropTypes.array,
};

export default AccountDownloadTileCertificate;
export { AccountDownloadTileCertificate };
