import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';
import { CERTIFICATE, ORIGINAL, SINGLE_PHOTO } from '../../../utils/variables';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import AccountDownloadTileButton from './AccountDownloadTileButton';
import CustomDialog from '../dialogs/CustomDialog';

const AccountDownloadDialogTile = ({
  icon,
  textInSpan,
  onScrollToMedia,
  notActive,
  type,
  certificateReadyForDwonload,
  originalsAreAvailable,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const renderMessageForCertificate = () => {
    if (!certificateReadyForDwonload && !originalsAreAvailable) {
      return (
        <FormattedMessageWrappedInSpan
          id="accountEventPage.accountDownloadDialogTile.certificateInstruction.noHighResAndData"
          defaultMessage="You will be able to download your personalized certificate after we have received the race results and the high-resolution images are available, usually within 24 hours."
        />
      );
    }
    if (!certificateReadyForDwonload) {
      return (
        <FormattedMessageWrappedInSpan
          id="accountEventPage.accountDownloadDialogTile.certificateInstruction.noResults"
          defaultMessage="You will be able to download your personalized certificate after we received the race results, usually within 24 hours."
        />
      );
    }
    if (!originalsAreAvailable) {
      return (
        <FormattedMessageWrappedInSpan
          id="accountEventPage.accountDownloadDialogTile.certificateInstruction.noHighRes"
          defaultMessage="You will be able to download your personalized certificate after the high-resolution images are available."
        />
      );
    }
  };

  return (
    <>
      <AccountDownloadTileButton
        icon={icon}
        textInSpan={textInSpan}
        notActive={notActive}
        onClick={() => setIsDialogOpen(true)}
      />
      <CustomDialog open={isDialogOpen} onRequestClose={() => setIsDialogOpen(false)}>
        <div className="notification-container">
          <DialogContent>
            <div className="notification-body">
              {type === SINGLE_PHOTO && (
                <>
                  <h1>
                    <FormattedMessageWrappedInSpan
                      id="accountEventPage.accountDownloadDialogTile.singlePhotoHeading"
                      defaultMessage="How to download a single photo?"
                      className="mb-15"
                    />
                  </h1>
                  <FormattedMessageWrappedInSpan
                    id="accountEventPage.accountDownloadDialogTile.singlePhotoInstruction"
                    defaultMessage="To download a single photo, choose a photo from the previews, click on it, and hit the download button."
                    values={{
                      br: <br />,
                    }}
                  />
                </>
              )}
              {type === ORIGINAL && (
                <>
                  <h1>
                    <FormattedMessageWrappedInSpan
                      id="accountEventPage.accountDownloadDialogTile.originalsHeading"
                      defaultMessage="We're on it"
                      className="mb-15"
                    />
                  </h1>
                  <FormattedMessageWrappedInSpan
                    id="accountEventPage.accountDownloadDialogTile.originalsInformation."
                    defaultMessage="High Resolution Photos will be soon ready to download."
                  />
                </>
              )}
              {type === CERTIFICATE && notActive && (
                <>
                  <h1>
                    <FormattedMessageWrappedInSpan
                      id="accountEventPage.accountDownloadDialogTile.certificateHeading.noOriginals"
                      defaultMessage="We're on it"
                      className="mb-15"
                    />
                  </h1>
                 {renderMessageForCertificate()}
                </>
              )}
              {type === CERTIFICATE && !notActive && (
                <>
                  <h1>
                    <FormattedMessageWrappedInSpan
                      id="accountEventPage.accountDownloadDialogTile.certificateHeading.withOriginals"
                      defaultMessage="How to download a certificate?"
                      className="mb-15"
                    />
                  </h1>
                  <FormattedMessageWrappedInSpan
                    id="accountEventPage.accountDownloadDialogTile.certificateInstruction.withOriginals"
                    defaultMessage="Please click on a picture below to download it as a certificate."
                  />
                </>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <div className="notification-action">
              <button
                className="accept"
                onClick={() => {
                  if (type === SINGLE_PHOTO || (type === CERTIFICATE && !notActive)) {
                    onScrollToMedia && onScrollToMedia();
                  }
                  setIsDialogOpen(false);
                }}
              >
                <FormattedMessageWrappedInSpan
                  id="searchInstructionsDialog.acceptButton"
                  defaultMessage="OK"
                />
              </button>
            </div>
          </DialogActions>
        </div>
      </CustomDialog>
    </>
  );
};

AccountDownloadDialogTile.propTypes = {
  icon: PropTypes.object,
  textInSpan: PropTypes.object,
  onScrollToMedia: PropTypes.func,
  notActive: PropTypes.bool,
  type: PropTypes.string,
};

export default AccountDownloadDialogTile;
