import React from 'react';
import PropTypes from 'prop-types';
import { FormattedTime, FormattedDate } from 'react-intl';

import ThumbnailPreviewRecheckButtons from './ThumbnailPreviewRecheckButtons';
import ThumbnailPreviewAccountButtons from './ThumbnailPreviewAccountButtons';
import { FormattedMessageWrappedInSpan } from '../../misc';
import { RECHECK_APPROVED, RECHECK } from '../../../utils/variables';

import EventAvailableRoundedIcon from '@material-ui/icons/EventAvailableRounded';
import TimerRoundedIcon from '@material-ui/icons/TimerRounded';
import CropOriginalRoundedIcon from '@material-ui/icons/CropOriginalRounded';
import SyncProblemRoundedIcon from '@material-ui/icons/SyncProblemRounded';

const ThumbnailPreviewAccountDetails = (props) => {
  const {
    photoSgId,
    photoNodeId,
    shotDate,
    originalUrl,
    socialMediaUrl,
    restartState,
    isFaceSearchEvent,
    hasCertificates,
    eventSgId,
    recheckRequestedAt,
    recheckStatus,
    onGoToAccountEventPage,
    recheckHasOrder,
    listType,
    certificateReadyForDwonload,
    hasBoughtFotoFlat,
    startNumbers,
  } = props;

  return (
    <div className="thumbnail-preview-details">
      <div className="row">
        <div className="col-xs-16 col-sm-6">
          <div className="thumbnail-preview-shortInfo ">
            <CropOriginalRoundedIcon />
            <span>{photoSgId}</span>
          </div>
          {shotDate && (
            <div className="thumbnail-preview-shortInfo ">
              <TimerRoundedIcon />
              <FormattedTime
                value={new Date(shotDate)}
                timeZone="UTC"
                hour="2-digit"
                minute="2-digit"
                second="2-digit"
              />
            </div>
          )}
          {recheckRequestedAt && (
            <div className="thumbnail-preview-shortInfo ">
              <EventAvailableRoundedIcon />
              <span>
                <FormattedMessageWrappedInSpan
                  id="thumbnailPreviewEventDetails.recheckRequestTime"
                  defaultMessage="Recheck requested"
                />{' '}
                <FormattedDate
                  value={new Date(recheckRequestedAt)}
                  day="numeric"
                  month="numeric"
                  year="numeric"
                  timeZone="UTC"
                />
                {', '}
                <FormattedTime
                  value={new Date(recheckRequestedAt)}
                  timeZone="UTC"
                  hour="2-digit"
                  minute="2-digit"
                />
              </span>
            </div>
          )}
        </div>
        <div className="thumbnail-preview-account-event-actions col-xs-16 col-sm-8 col-sm-offset-2">
          <div className="thumbnail-download-btns">
            {listType === RECHECK && (
              <ThumbnailPreviewRecheckButtons
                recheckStatus={recheckStatus}
                onGoToAccountEventPage={onGoToAccountEventPage}
                recheckHasOrder={recheckHasOrder}
              />
            )}
            {(listType != RECHECK ||
              (listType === RECHECK && recheckStatus === RECHECK_APPROVED)) && (
              <ThumbnailPreviewAccountButtons
                photoNodeId={photoNodeId}
                eventSgId={eventSgId}
                isFaceSearchEvent={isFaceSearchEvent}
                hasCertificates={hasCertificates}
                listType={listType}
                originalUrl={originalUrl}
                socialMediaUrl={socialMediaUrl}
                restartState={restartState}
                recheckHasOrder={recheckHasOrder}
                certificateReadyForDwonload={certificateReadyForDwonload}
                startNumbers={startNumbers}
                hasBoughtFotoFlat={hasBoughtFotoFlat}
              />
            )}
          </div>
          {!originalUrl && restartState == 'processing' && (
            <span className="thumbnail-preview-info-about-processing">
              <SyncProblemRoundedIcon />
              <FormattedMessageWrappedInSpan
                id="thumbnailPreviewAccountDetails.highResolutionsailableSoon"
                defaultMessage="This photo will be available in high resolution soon. Then you will have additional actions available here."
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

ThumbnailPreviewAccountDetails.propTypes = {
  photoSgId: PropTypes.number,
  photoNodeId: PropTypes.string,
  shotDate: PropTypes.string,
  socialMediaUrl: PropTypes.string,
  originalUrl: PropTypes.string,
  restartState: PropTypes.string,
  startNumbers: PropTypes.array,
  isFaceSearchEvent: PropTypes.bool,
  hasCertificates: PropTypes.bool,
  eventSgId: PropTypes.number,
  recheckRequestedAt: PropTypes.string,
  recheckStatus: PropTypes.string,
  onGoToAccountEventPage: PropTypes.func,
  recheckHasOrder: PropTypes.bool,
  listType: PropTypes.string,
  certificateReadyForDwonload: PropTypes.bool,
  hasBoughtFotoFlat: PropTypes.bool,
};

export default ThumbnailPreviewAccountDetails;
